import { defineStore } from 'pinia';
import { computed } from 'vue';

import { getVuexStore } from '@console/state/vuex/store';
import storage from '@shared/lib/local_storage';
import env from '@shared/utilities/env';

// Only change required is to add/remove feature flags here and add it to the reloadRequired/releasedFeatures set if needed
export const allFeatureFlags = [
  'awsClrWadDropdown',
  'awsAllDiscounts',
  'nonComputeCallToAction',
  'perOfferingAccountStatus',
  'armScheduler',
  'gcCloudSql',
  'azurePortfolioAllocationWidget',
  'azureSpendCoverageWidget',
  'azureSpendCoverageTrendWidget',
  'azureDailySpendCoverageWidget',
  'azureMultiTenantOnboarding',
  'azureNetSavingsTrendWidget',
  'spalSettings',
  'azureOnboardingCustomManagementGroupId'
] as const;
export type FeatureFlag = typeof allFeatureFlags[number];

const releasedFeatures = new Set<FeatureFlag>([]);
const reloadRequired = new Set<FeatureFlag>([]);

type FeatureFlagState = FeatureFlag extends never
  ? // when there are no feature flags defined, this prevents type errors in the following code
    Record<string, boolean>
  : // If there are feature flags defined, use the correct type
    Record<FeatureFlag, boolean>;

const buildInitialState = () => {
  const isLocalOrDev = !env.isProduction();
  const storedState: Partial<FeatureFlagState> = storage.get('featureFlags') ?? {};
  const initialState = {} as FeatureFlagState;
  const queryParams = new URLSearchParams(window.location.search);

  allFeatureFlags.forEach(flag => {
    initialState[flag] = releasedFeatures.has(flag) || queryParams.has(flag) || (storedState[flag] ?? isLocalOrDev);
  });

  return initialState;
};

export const useFeatureStore = defineStore('feature', {
  state: () => buildInitialState(),
  getters: {
    featureFlags(state) {
      const store = getVuexStore();
      const isDemo = store.getters['customer/isDemo'];
      return allFeatureFlags.reduce(
        (acc, flag) => ({ ...acc, [flag]: (!isDemo || releasedFeatures.has(flag)) && state[flag] }),
        {} as Record<FeatureFlag, boolean>
      );
    },
  },
  actions: {
    toggleFeatureFlag(flag: FeatureFlag) {
      this[flag] = !this[flag];
      storage.set('featureFlags', this.$state);
      if (reloadRequired.has(flag)) {
        location.reload();
      }
    },
    toggleAllFeatureFlags() {
      const entries = Object.entries(this.$state);
      const areAllEnabled = entries.every(([, value]) => value);
      const flagsToToggle = areAllEnabled
        ? allFeatureFlags
        : entries.filter(([, value]) => !value).map(([key]) => key as FeatureFlag);

      flagsToToggle.forEach(flag => (this[flag] = !this[flag]));
      storage.set('featureFlags', this.$state);
      if (flagsToToggle.some(flag => reloadRequired.has(flag))) {
        location.reload();
      }
    },
  },
});

export const useFeatureFlag = (flag: FeatureFlag) => {
  const store = useFeatureStore();
  return computed(() => store[flag]);
};
